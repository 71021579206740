import { inject, useView }  from 'aurelia-framework';
import { AppContainer }     from 'resources/services/app-container';
import { CustomDialog }     from 'resources/elements/html-elements/dialogs/custom-dialog';
import { DialogController } from 'aurelia-dialog';

@useView('resources/elements/html-elements/dialogs/custom-dialog.html')
@inject(AppContainer, DialogController)
export class AnswerSealingRequestDialog extends CustomDialog {

    defaultSettings = {
        title:         null,
        body:          null,
        resource:      null,
        buttons:       false,
        customButtons: [
            {
                class:  'btn-link',
                action: this.cancel.bind(this),
                label:  'dialog.button.keep-editing',
            },
            {
                class:  'btn-primary',
                action: this.submit.bind(this),
                label:  'dialog.button.answer-request',
            },
        ],
    };

    /**
     * Handles activate event
     */
    activate(settings) {
        super.activate(settings);

        this.settings.body  = this.appContainer.i18n.tr('dialog.body.answer-sealing-request');
        this.settings.title = this.appContainer.i18n.tr('dialog.title.answer-sealing-request');
    }

}
