import { PLATFORM } from 'aurelia-pal';

export function configure(config) {
    config.globalResources(
        [
            PLATFORM.moduleName('./accordion'),
            PLATFORM.moduleName('./accordion-section'),
            PLATFORM.moduleName('./aurelia-modal'),
            PLATFORM.moduleName('./datatable'),
            PLATFORM.moduleName('./edit-record'),
            PLATFORM.moduleName('./fab-action-menu'),
            PLATFORM.moduleName('./fab-action-button'),
            PLATFORM.moduleName('./fab-action-menu-button'),
            PLATFORM.moduleName('./fancy-tree'),
            PLATFORM.moduleName('./fieldset-bordered'),
            PLATFORM.moduleName('./new-record'),
            PLATFORM.moduleName('./panel-group'),
            PLATFORM.moduleName('./panel-header'),
            PLATFORM.moduleName('./panel-header-elements'),
            PLATFORM.moduleName('./tab-section'),
            PLATFORM.moduleName('./tab-sections'),
            PLATFORM.moduleName('./tabs'),
            PLATFORM.moduleName('./tabs-nav'),
            PLATFORM.moduleName('./tabs-nav-element'),
        ],
    );
}
