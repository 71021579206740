// TODO - THINK OF A BETTER WAY TO AVOID ALMOST REPEAT CODE OF `fab-action-menu` / `fab-action-menu-button`
import { bindable, containerless, inject } from 'aurelia-framework';
import { Router }                          from 'aurelia-router';

@inject(Router)
@containerless()
export class FabActionButton {

    @bindable type     = 'fixed';
    @bindable position = 'bottom-right';
    @bindable colour   = 'bg-danger';
    @bindable icon     = 'icon-plus3';
    @bindable action   = () => {
    };

    @bindable route = null;

    /**
     * Constructor
     *
     * @param router
     */
    constructor(router) {
        this.router = router;
    }

    /**
     * Handles button clicked
     *
     * @param $event
     */
    buttonClicked($event) {
        this.action($event);
    }

}
