import { inject, useView }  from 'aurelia-framework';
import { AppContainer }     from 'resources/services/app-container';
import { CustomDialog }     from 'resources/elements/html-elements/dialogs/custom-dialog';
import { DialogController } from 'aurelia-dialog';

@useView('resources/elements/html-elements/dialogs/custom-dialog.html')
@inject(AppContainer, DialogController)
export class RejectScheduleDialog extends CustomDialog {

    defaultSettings = {
        title:         null,
        body:          null,
        resource:      null,
        buttons:       false,
        customButtons: [
            {
                class:  'btn-link',
                action: this.cancel.bind(this),
                label:  'dialog.button.keep-editing',
            },
            {
                class:  'btn-danger',
                action: this.submit.bind(this),
                label:  'dialog.button.reject-procedure-schedule',
            },
        ],
    };

    /**
     * Handles activate event
     */
    activate(settings) {
        super.activate(settings);

        this.settings.type = this.appContainer.i18n.tr(settings.type).toLowerCase();

        this.settings.body                   = this.appContainer.i18n.tr(this.settings.body, { type: this.settings.type, interpolation: { escapeValue: false } });
        this.settings.title                  = this.appContainer.i18n.tr('dialog.title.reject-procedure-schedule', { type: this.settings.type, interpolation: { escapeValue: false } });
        this.settings.customButtons[1].label = this.appContainer.i18n.tr('dialog.button.reject-procedure-schedule', { type: this.settings.type, interpolation: { escapeValue: false } });
    }

}
