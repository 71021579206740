import { bindable, containerless, inject } from 'aurelia-framework';
import { AppContainer }                    from 'resources/services/app-container';
import { Router }                          from 'aurelia-router';

@containerless()
@inject(AppContainer, Router)
export class FabActionMenuButton {

    @bindable colour = 'btn-light';
    @bindable action = () => {
    };
    @bindable route  = null;
    @bindable title  = null;
    @bindable icon   = null;

    /**
     * Constructor
     *
     * @param appContainer
     * @param router
     */
    constructor(appContainer, router) {
        this.theme  = appContainer.theme;
        this.router = router;
    }

    /**
     * Handles button clicked
     *
     * @param $event
     */
    buttonClicked($event) {
        this.action($event);
    }

}
