import { inject, useView }  from 'aurelia-framework';
import { AppContainer }     from 'resources/services/app-container';
import { CustomDialog }     from 'resources/elements/html-elements/dialogs/custom-dialog';
import { DialogController } from 'aurelia-dialog';

@useView('resources/elements/html-elements/dialogs/custom-dialog.html')
@inject(AppContainer, DialogController)
export class ValidateLiftDialog extends CustomDialog {

    defaultSettings = {
        title:         'dialog.title.validate-lift',
        body:          'dialog.body.validate-lift',
        resource:      null,
        buttons:       false,
        customButtons: [
            {
                class:  'btn-link',
                action: this.cancel.bind(this),
                label:  'dialog.button.cancel',
            },
            {
                class:  'btn-primary',
                action: this.submit.bind(this),
                label:  'dialog.button.validate-lift',
            },
        ],
    };

}
