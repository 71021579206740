import { bindable } from 'aurelia-framework';
import environment  from 'environment';

export class GoogleMaps {

    map;
    marker;
    geocoder;
    containerUuid = generateUuid();

    @bindable latitude;
    @bindable longitude;
    @bindable address;

    /**
     * Handles attached event
     */
    attached() {
        this.initMap();
        this.initGeocoder();
    }

    /**
     * Initializes map
     */
    initMap() {
        this.map = new google.maps.Map(document.getElementById(this.containerUuid), {
                zoom:   8,
                center: {
                    lat: this.latitude,
                    lng: this.longitude,
                },
            },
        );
    }

    /**
     * Initializes geocoder
     */
    initGeocoder() {
        this.geocoder = new google.maps.Geocoder();
    }

    /**
     * Handles address change
     */
    addressChanged() {
        this.geocoder.geocode({'address': this.address}, (results, status) => {
            if (status === 'OK') {
                this.map.setCenter(results[0].geometry.location);

                this.marker = new google.maps.Marker({
                    map:      this.map,
                    position: results[0].geometry.location,
                });
            } else {
                alert('Geocode was not successful for the following reason: ' + status);
            }
        });
    }

    /**
     * Handles attached event
     */
    loadMapsApi() {
        if (this.url) {
            this.scripttag = document.createElement('script');

            this.scripttag.async = true;
            this.scripttag.defer = true;

            this.scripttag.setAttribute('src', `${environment.googleMapsApi.url}?key=${environment.googleMapsApi.key}&callback=initMap`);

            document.body.appendChild(this.scripttag);
        }
    }

}
