import { bindable, computedFrom, containerless } from 'aurelia-framework';
import 'assets/js/plugins/ui/fab.min';

@containerless()
export class FabActionMenu {

    @bindable type      = 'fixed';
    @bindable position  = 'bottom-right';
    @bindable toggle    = 'click';
    @bindable colour    = 'bg-danger';
    @bindable openIcon  = 'icon-plus3';
    @bindable closeIcon = 'icon-cross2';

    opened = false;

    /**
     * Returns button state
     *
     * @returns {string}
     */
    @computedFrom('opened')
    get state() {
        return this.opened ? 'open' : 'close';
    }

    /**
     * Toggles button state
     */
    toggleState() {
        this.opened = !this.opened;
    }

}
