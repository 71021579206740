import { inject }           from 'aurelia-framework';
import { AppContainer }     from 'resources/services/app-container';
import { DialogController } from 'aurelia-dialog';

@inject(AppContainer, DialogController)
export class CustomDialog {

    defaultSettings = {
        title:         null,
        body:          null,
        buttons:       false,
        customButtons: [],
    };

    /**
     * Constructor
     *
     * @param appContainer
     * @param dialogController
     */
    constructor(appContainer, dialogController) {
        this.appContainer     = appContainer;
        this.dialogController = dialogController;
    }

    /**
     * Handles activate event
     */
    activate(settings) {
        this.settings = $.extend(true, {}, this.defaultSettings, settings);
    }

    /**
     * Submits modal
     */
    submit() {
        return this.dialogController.ok();
    }

    /**
     * Cancels dialog
     */
    cancel() {
        return this.dialogController.cancel();
    }

}
